<template>
  <div class="box">
    <div class="step-bgc">
      <img src="../assets/img/pic_step_three.png" />
    </div>
    <!--<div class="form-item">-->
      <!--<div class="title">-->
        <!--是否最高学历-->
      <!--</div>-->
      <!--<div>-->
        <!--<van-field class="padding0" v-model="education" readonly right-icon="arrow" type="text" placeholder="请选择是否最高学历"  @click="educationShow = true"/>-->
      <!--</div>-->
      <!--<div class="border-line">-->
      <!--</div>-->
    <!--</div>-->
    <div class="form-item">
      <div class="title">
        学历(个人信息)
      </div>
      <div>
        <van-field class="padding0" v-model="educationType" readonly right-icon="arrow" type="text" placeholder="请选择学历"  @click="educationTypeShow = true"/>
      </div>
      <div class="border-line">
      </div>
    </div>
    <div class="form-item">
      <div class="title">
        学校
      </div>
      <div>
        <van-field class="padding0" v-model="schoolName" clearable placeholder="请填写学校名称"  />
      </div>
      <div class="border-line">
      </div>
    </div>
    <div class="form-item">
      <div class="title">
        专业
      </div>
      <div>
        <van-field class="padding0" v-model="Professional" clearable placeholder="请填写学校专业"  />
      </div>
      <div class="border-line">
      </div>
    </div>
    <div class="form-item">
      <div class="title">
        入学日期
      </div>
      <div>
        <van-field class="padding0" v-model="enrollmentDate" readonly right-icon="arrow" type="text" placeholder="请选择入学日期"  @click="showDate = true"/>
      </div>
      <div class="border-line">
      </div>
    </div>
    <div class="form-item">
      <div class="title">
        毕业日期
      </div>
      <div>
        <van-field class="padding0" v-model="graduateDate" readonly right-icon="arrow" type="text" placeholder="请选择毕业日期"  @click="graduateDateShow = true"/>
      </div>
      <div class="border-line">
      </div>
    </div>
    <div class="form-item">
      <div class="title">
        学制类型
      </div>
      <div>
        <van-field class="padding0" readonly right-icon="arrow" v-model="eductionalSystme" clearable placeholder="请选择学制类型" @click="eductionalSystmeShow = true" />
      </div>
      <div class="border-line">
      </div>
    </div>
    <div class="form-item">
      <div class="title">
        学位
      </div>
      <div>
        <van-field class="padding0" @click="degreeShow = true" readonly right-icon="arrow" v-model="degree" clearable placeholder="请选择学位"  />
      </div>
      <div class="border-line">
      </div>
    </div>
    <van-action-sheet v-model="educationTypeShow" :actions="educationTypeList" @select="educationTypeSelect" cancel-text="取消"/>
    <!--学制类型-->
    <van-action-sheet v-model="eductionalSystmeShow" :actions="eductionalSystmeList" @select="eeductionalTypeSelect" cancel-text="取消"/>
    <!--学位-->
    <van-action-sheet v-model="degreeShow" :actions="degreeList" @select="degreeTypeSelect" cancel-text="取消"/>

    <van-popup v-model="showDate" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatter"
        @confirm="enrollmentDateChange"
      />
    </van-popup>

    <van-popup v-model="graduateDateShow" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatter"
        @confirm="graduateDateChange"
      />
    </van-popup>
  </div>
</template>

<script>
  import axios  from 'axios';
  import {Field,Popup,ActionSheet,DatetimePicker} from 'vant';
  import moment from 'moment';
  export default {
    name: 'Home',
    components: {
      [Field .name]: Field,
      [Popup.name]: Popup,
      [ActionSheet.name]: ActionSheet,
      [DatetimePicker.name]: DatetimePicker
    },
    data(){
      this.moment = moment;
      return {
        minDate: new Date(1970, 0, 1),
        maxDate: new Date(2099, 10, 1),
        currentDate: new Date(),
        graduateDateShow:false,
        showDate:false,
        educationType:'',//学历类型
        schoolName:'',//学校名称
        Professional:'',//专业
        enrollmentDate:'',//入学日期
        graduateDate:'',//毕业日期
        eductionalSystme:'',//学制
        eductionalSystmeShow:false,
        eductionalSystmeList:[{name:'全日制',id:1},{name:'成人',id:2},{name:'自考',id:3},{name:'电大',id:4},{name:'函授',id:5},{name:'夜大',id:6}],//学制
        degree:'',//学位
        degreeShow:false,
        degreeList:[{name:'博士',id:1},{name:'硕士',id:2},{name:'学士',id:3},{name:'无学位',id:4}],
        educationTypeShow:false,
        educationTypeList:[{name:'硕士及以上',id:1},{name:'本科',id:2},{name:'大专',id:3},{name:'高中',id:4},{name:'初中及以下',id:5}],
        from:{},
        urlQuery:{}
      }
    },
    mounted(){
      let query = {};

      if (this.$route.query.pk_psnbasdoc) {
        query.pk_psnbasdoc = this.$route.query.pk_psnbasdoc;
      }
      if (this.$route.query.pk_corp) {
        query.pk_corp = this.$route.query.pk_corp;
      }
      if (this.$route.query.pk_psndoc) {
        query.pk_psndoc = this.$route.query.pk_psndoc;
      }
      if (this.$route.query.psnname) {
        query.psnname = this.$route.query.psnname;
      }
      if (this.$route.query.psncode) {
        query.psncode = this.$route.query.psncode;
      }
      if (this.$route.query.mobile) {
        query.mobile = this.$route.query.mobile;
      }
      console.log(query,'query获取参数');
      this.urlQuery = query;
    },
    methods:{
      enrollmentDateChange(value){
        this.enrollmentDate = this.moment(value).format("YYYY-MM-DD");
        this.showDate = false;
      },
      graduateDateChange(value){
        this.graduateDate = this.moment(value).format("YYYY-MM-DD");
        this.graduateDateShow = false;
      },
      formatter(type, val) {
        if (type === 'year') {
          return `${val}年`;
        } else if (type === 'month') {
          return `${val}月`;
        }else if (type === 'day') {
          return `${val}日`;
        }
        return val;
      },
      eeductionalTypeSelect(item){
        this.eductionalSystme = item.name;
        this.eductionalSystmeShow = false;
      },
      degreeTypeSelect(item){
        this.degree = item.name;
        this.degreeShow = false;
      },
      educationTypeSelect(item){
        this.educationType = item.name;
        this.educationTypeID = item.id;
        this.educationTypeShow = false;
      },

      stepClcik(){
        let msg = this.validationRule();
        if (msg){
          return this.$toast.fail(msg)
        }
        this.from = JSON.parse(sessionStorage.getItem('form'));
        let form = {
          education:this.educationType,
          educationID:this.educationTypeID,
          school:this.schoolName,
          major:this.Professional,
          graduationtime:this.graduateDate,
          admissiontime:this.enrollmentDate,
          edusystem:this.eductionalSystme,
          degree:this.degree
        }
        const createdForm = Object.assign({},form,this.from,this.urlQuery);
        sessionStorage.setItem('form',JSON.stringify(createdForm));
        let _this = this;
        axios({
        method: 'post',
        url: 'https://api-sit.dahuifuwu.com/v1/app/HrPush',
        data: createdForm
        })
        .then(function (response) {
         if(response.data.code === 200){
           _this.$toast.success('提交成功！');
           _this.$router.push('/success');
         }else{
           _this.$toast.fail(response.data.msg);
         }

        })
        // .catch(function (response) {
        //
        // });
      },
      validationRule(){
        if (!this.educationType){
          return '请选择学历类型';
        }
        if (!this.schoolName && this.educationTypeID <4){
          return '请输入学校名称';
        }
        if (!this.Professional && this.educationTypeID <4){
          return '请输入学校专业';
        }
        if (!this.enrollmentDate && this.educationTypeID <4){
          return '请选择入学时间';
        }
        if (!this.graduateDate && this.educationTypeID <4){
          return '请选择毕业日期';
        }
        // if (!this.enrollmentDate > this.graduateDate){
        //   return '入学时间需要小于毕业时间';
        // }
        if (!this.eductionalSystme && this.educationTypeID <4){
          return '请选择学制类型';
        }
        if (!this.degree && this.educationTypeID <4){
          return '请选择学位';
        }
      }
    }
  }
</script>
<style scoped>
  @import "../assets/style/index.css";

</style>
