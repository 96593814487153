<template>
    <div class="box">
      <div class="success">
        <img src="../assets/img/success.png"/>
      </div>
      <div class="btn-area btn-margin">
        <van-button class="btn-style" type="info" @click="goBack">返回</van-button>
      </div>
    </div>
</template>

<script>
  import {Button} from 'vant';
  export default {
    name: 'success',
    components: {
      [Button.name]: Button
    },
    methods:{
      goBack(){
        this.$router.push('/');
      }
    }
  }
</script>

<style scoped>
  @import "../assets/style/index.css";
  .success {
    width: 100%;
    text-align: center;
    margin-top: 100px;
  }
  .success img {
    width: 70%;
  }
  .btn-margin {
    margin-top: 35px!important;
  }
</style>
