<template>
    <div>
      <stepOne v-show="step===1" ref="stepOne" @vaildData="(val)=>{this.stepValue(val)}"></stepOne>
      <stepTwo v-show="step===2" ref="stepTwo" @vaildData="(val)=>{this.stepValue(val)}"></stepTwo>
      <stepThree v-show="step===3" ref="stepThree"></stepThree>
      <div>
        <div class="btn-area" v-show="step===1">
          <van-button class="btn-style" type="info" @click="stepOneClcik('One')">下一步</van-button>
        </div>
        <div class="btn-area btn-fstle" v-show="step===2">
          <van-button class="btn-style btn-width" @click="goBackPage('Two')">上一步</van-button>
          <van-button class="btn-style btn-width" type="info" @click="stepOneClcik('Two')">下一步</van-button>
        </div>
        <div class="btn-area btn-fstle" v-show="step===3">
          <van-button class="btn-style btn-width" @click="goBackPage('Three')">上一步</van-button>
          <van-button class="btn-style btn-width" type="info" @click="stepOneClcik('Three')">提交</van-button>
        </div>
      </div>
    </div>
</template>

<script>
  import stepOne from './stepOne'
  import stepTwo from './stepTwo'
  import stepThree from './stepThree'
  import {Button} from 'vant';
  export default {
    name: 'index',
    components:{
      stepOne,
      stepTwo,
      stepThree,
      [Button.name]: Button
    },
    data(){
      return {
        step:1,
      }
    },
    methods:{
      stepOneClcik(val){
        let time = setTimeout(()=>{
          this.$refs[`step${val}`].stepClcik()
          clearTimeout(time)
        },0)
      },
      goBackPage(val){
        if (val==='Two'){
          this.step = 1;
        } else {
          this.step = 2;
        }
      },
      stepValue({status}){
        this.step = status;
      }
    },
  }
</script>

<style scoped>
  @import "../assets/style/index.css";
  .btn-fstle {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .btn-width {
    width: 30vw!important;
  }
</style>
