<template>
  <div class="box">
    <div class="step-bgc">
      <img src="../assets/img/pic_step_two.png" />
    </div>
    <div class="form-item">
      <div class="title">
        政治面貌
      </div>
      <div>
        <van-field class="padding0" v-model="politics" readonly right-icon="arrow" type="text" placeholder="请选择政治面貌"  @click="ContactShow = true"/>
      </div>
      <div class="border-line">
      </div>
    </div>
<!--    <div class="form-item">-->
<!--      <div class="title">-->
<!--        入职介绍人人员编码-->
<!--      </div>-->
<!--      <div>-->
<!--        <van-field class="padding0" v-model="linkman" clearable placeholder="请填写入职介绍人编码"  />-->
<!--      </div>-->
<!--      <div class="border-line">-->
<!--      </div>-->
<!--    </div>-->
    <div class="form-item">
      <div class="title">
        发放银行(选填)
      </div>
      <div>
        <van-field class="padding0" v-model="IssuingBank" readonly right-icon="arrow" type="text" placeholder="请选择发放银行"  @click="showPicker = true"/>
      </div>
      <div class="border-line">
      </div>
    </div>
    <div class="form-item">
      <div class="title1" >
        银行卡号（必须为本人身份证办理，否则工资无法发放）
      </div>
      <div>
        <van-field class="padding0" v-model="bankNumber" type="number" clearable placeholder="请填写银行卡号"  />
      </div>
      <div class="border-line">
      </div>
    </div>
    <van-action-sheet v-model="ContactShow" :actions="ContactList" @select="ContactSelect" cancel-text="取消"/>
    <van-action-sheet v-model="showPicker" :actions="bankList" @select="bankSelect" cancel-text="取消"/>
  </div>
</template>

<script>
  import {Field,Popup,ActionSheet } from 'vant';
  export default {
    name: 'Home',
    components: {
      [Field .name]: Field,
      [Popup.name]: Popup,
      [ActionSheet.name]: ActionSheet,
    },
    data(){
      return {
        // linkman:'',
        IssuingBank:'',
        bankNumber:'',
        politics:'',
        ContactShow:false,
        showPicker:false,
        ContactList:[{name:'群众',id:1},{name:'共青团员',id:2},{name:'中共预备党员',id:3},{name:'中共党员',id:4},{name:'民革会员',id:5},{name:'民盟盟员',id:6},{name:'民建会员',id:7},{name:'民进会员',id:8},{name:'农工党党员',id:9},{name:'致公党党员',id:10},{name:'九三学社社员',id:11},{name:'台盟盟员',id:12},{name:'无党派民主人士',id:13}],
        bankList:[{name:'',id:0},{name:'中国工商银行',id:1},{name:'中国农业银行',id:2},{name:'中国银行',id:3},{name:'中国建设银行',id:4},{name:'交通银行',id:5},{name:'招商银行',id:6},{name:'中国邮政储蓄银行',id:7},{name:'南京银行',id:8},{name:'华夏银行',id:9},{name:'平安银行',id:10},{name:'上海浦东发展银行',id:11},{name:'兴业银行',id:12},{name:'中国民生银行',id:13},{name:'青岛农商银行',id:14},{name:'北京银行',id:15},{name:'青岛银行股份有限公司',id:16},{name:'江苏银行',id:17},{name:'中信银行',id:18},{name:'深圳平安银行',id:19},{name:'广东发展银行',id:20}],
        from:{}
      }
    },
    styleObject: {
      color: 'red'
    },
    methods:{
      ContactSelect(item){
        this.politics = item.name;
        this.ContactShow = false;
      },
      bankSelect(item){
        this.IssuingBank = item.name;
        this.showPicker = false;
      },
      stepClcik(){
        let msg = this.validationRule();
        if (msg){
          return this.$toast.fail(msg)
        }
        this.from = JSON.parse(sessionStorage.getItem('form'));
        let form = {
          // rzjsr:this.linkman,//入职介绍人
          bankname:this.IssuingBank,//银行
          bankcode:this.bankNumber,//卡号
          politics:this.politics
        }
        this.validEvent()
        const createdForm = Object.assign({},form,this.from);
        sessionStorage.setItem('form',JSON.stringify(createdForm));
      },
      validEvent(){
        this.$emit('vaildData',{
          status:3
        })
      },
      validationRule(){
        if (!this.politics){
          return '请选择政治面貌';
        }
        // if (!this.linkman && this.channel === '内荐'){
        //   return '请填写入职介绍人';
        // }
        if (this.IssuingBank){
          if (!this.bankNumber){
            return '请输入银行卡号';
          }
        }
        if (this.bankNumber){
          if (!this.IssuingBank){
            return '请选择银行';
          }
        }
      }
    }
  }
</script>
<style scoped>
  @import "../assets/style/index.css";
</style>
